<template>
    <v-container fluid>
        <v-row>
            <v-col
                cols="12"
                md="8"
            >
                <h2>{{ CompanyName }}</h2>
                {{ CompanyAddress }}
                <br>
                {{ CompanyCity }}
                <br>
                {{ $t('admin/cvr-vat') }}: {{ CompanyCvr }}
                <br>
                {{ $t('contact-phonenr') }}: {{ CompanyPhoneNumber }}
            </v-col>

            <b />
            <br>
            <b />
            <br>
            <b />
            <br><br>
        </v-row>
    </v-container>
</template>

<script>
export default {
    components: {

    },
    data() {
        return {
        }
    },
    computed: {
        CompanyName() {
            return process.env.VUE_APP_TERMS_COMPANY_NAME
        },
        CompanyAddress() {
            return process.env.VUE_APP_TERMS_COMPANY_ADDRESS
        },
        CompanyCity() {
            return process.env.VUE_APP_TERMS_COMPANY_ZIPCODE_AND_CITY
        },
        CompanyCvr() {
            return process.env.VUE_APP_TERMS_COMPANY_CVR
        },
        CompanyPhoneNumber() {
            return process.env.VUE_APP_TERMS_COMPANY_PHONE_NUMBER
        },
    },
    created() {
    },
    methods: {
    }
}
</script>